/**
 * Created by dpardell on 23/11/2015.
 */

angular
    .module('annexaApp')
    .controller('RegOutputController',['$scope', '$rootScope', '$state', 'HeaderService', function ($scope, $rootScope, $state, HeaderService) {

        //region General

        HeaderService.onChangeState($scope, function(message) {
            if(_.contains(['annexa.reg.output.entries', 'annexa.reg.output.notifications'], message.state.name)) {
                $rootScope.headButtons = [];
                $rootScope.subHeadButtons = [ new HeadButtonSearch('#tableFilter') ];
                $rootScope.subHeadTabs = [
                    { state:'annexa.reg.output.entries', type:'breadDivButton', name:'global.literals.outputs', permissions: ['view_output_register', 'view_all_output_register'] }
                ];
                if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.show_notification_functionality && $rootScope.app.configuration.show_notification_functionality.value){
                    $rootScope.subHeadTabs.push({ state:'annexa.reg.output.notifications', type:'breadDivButton active', name:'global.literals.notifications', permissions: ['view_notifications', 'view_all_notifications'] });
                }
            }

            if(message.state.name == 'annexa.reg.output.entries') {
                $rootScope.headButtons = [ new HeadButton('btn primary breadDivButton mb-xs-pt', undefined, '#dataTableOutput','fa-plus', 'global.reg.output.new', undefined, 'newOutput').setPermissions('new_output_entry_register') ];
                $rootScope.subHeadTabs[0].type = 'breadDivButton active';
                if($rootScope.subHeadTabs[1]){
                    $rootScope.subHeadTabs[1].type = 'breadDivButton';
                }
            }
        });

        HeaderService.changeState($state.current);

        //endregion

    }])
    .controller('RegOutputEntriesController',['$scope', '$rootScope', '$filter', '$state', 'RegFactory', 'ErrorFactory', 'RouteStateFactory', 'TableFilterFactory', 'GlobalDataFactory', 'Language', 'DialogsFactory', function($scope, $rootScope, $filter, $state, RegFactory, ErrorFactory, RouteStateFactory, TableFilterFactory, GlobalDataFactory, Language, DialogsFactory) {

        //region Filter
    	
    	// ORDENAR EL LLISTAT DE REGISTRE //
        $scope.tableOrderProperties = { sortName: 'registerDate', sort: [[0,'desc']] };


        var managed = [
            { id: true, name: 'global.reg.literals.managed' },
            { id: false, name: 'global.reg.literals.noManaged' }
        ]

        $scope.filter = [
            { id: 'entryNumber', type: 'text', order: 1, label: 'global.literals.numberOuput' },
            { id: 'procedureName', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true },
            { id: 'dossierNumber', type: 'text', order: 3, label: 'global.literals.numExp', callAux: true },
            { id: 'registerEntryChannel', type: 'select', order: 4, label: 'global.literals.channel', dataType: 'LOCAL', data: GlobalDataFactory.allRegisterEntryChannelsOutput, addAll: true, nameProperty: Language.getActiveColumn() },
            { id: 'extract', type: 'text', order: 5, label: 'global.literals.extract' },
            { id: 'dataReg', type: 'dateRange', order: 6, label: 'global.literals.regFrom', callAux: true },
            { id: 'entryDocumentType', type: 'select', order: 8, label: 'global.literals.regType', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryDocumentTypesOutput, addAll: true, nameProperty: Language.getActiveColumn() },
            { id: 'entryClassification', type: 'select', order: 9, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryClassificationsOutput, addAll: true, nameProperty: Language.getActiveColumn() },
            { id: 'representant', type: 'text', order: 11, label: 'global.literals.destiny', callAux: true },
            { id: 'managed', type: 'select', order: 10, label: 'global.literals.state', dataType: 'LOCAL', data: managed, addAll: true, nameProperty: 'name' },
            { id: 'observations', type: 'text', order: 12, label: 'global.literals.observations' },
            { id: 'originEntryNumber', type: 'text', order: 15, label: 'global.literals.originEntryInputNumber' },
            { id: 'withoutDocuments', type: 'checkbox', order: 13, label: 'global.literals.withoutDocuments', callAux: true},
            { id: 'address', type: 'text', order: 14, label: 'global.territory.list.address', callAux: true }
        ];

        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
            $scope.filter.unshift({ id: 'registerEntryOffice', type: 'select', order: 0, label: 'global.literals.register_office', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryOffices, addAll: true, nameProperty: Language.getActiveColumn() });
        }

        var routeState = RouteStateFactory.getRouteState($state.current);

        if(routeState) {
            $scope.filter = routeState.state;
        }

        //endregion

        //region Table

        var getFilterCall = function () {
            var filterCall = TableFilterFactory.getFilterCall($scope.filter);

            filterCall.entryType = 'OUTPUT';

            if(filterCall && filterCall.managed && filterCall.managed.id) {
                filterCall.managed = filterCall.managed.id;
            }

            return filterCall;
        }

        var getFilterCallAux = function () {
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.filter);

            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.langColumn = Language.getActiveColumn();

            return filterCallAux;
        }

        var columns = [
            { id: 'entryNumber', width: '110px', title: $filter('translate')('global.literals.numberOuput') },
            { id: 'registerDate', width: '110px', column: new DateTimeColumn($filter,'global.reg.datatable.registerDate') },
            { id: 'registerEntryChannel.'+ Language.getActiveColumn(), width: '60px', title: $filter('translate')('global.literals.channel') },
            { id: 'extract', title: $filter('translate')('global.literals.extract'), className: 'pre-line', sortable: false },
            { id: 'thirds', width: '200px', column: new PrincipalThirdColumn($filter,'global.literals.principalDestiny'), sortable: false },
            { id: 'actions', columnName: 'id', width: '100px', className: 'text-center', sortable: false, column: new ActionsColumn(
                    $filter('translate')('global.literals.actions'),
                    new ActionButton('global.literals.see','annexa.reg.output.entries.edit({ output: \'[data]\'})','fa-eye', true),
                    [ new ActionButton('global.reg.literals.generateEvidenceDocument','createSuportingDocument([data])','', false) ]
                )}
        ]

        if($rootScope.app.configuration.reg_pending.value) {
            columns.splice(columns.length - 1, 0, { id: 'pending', width: '20px', title: $scope.headerPendingColumn(), render: $scope.pendingColumnRender, sortable: false })
        }

        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
            columns.unshift({ id: 'registerEntryOffice.acronym', width: '50px', title: $filter('translate')('global.literals.office') });
            $scope.tableOrderProperties.sort.index += 1;
        }

        var datatableSettings = $rootScope.app.configuration.datatables_settings;

        columns = getDatatableColumnsSettings(datatableSettings, 'datatable_register_entry_output', columns);

        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_register_entry_output', columns);

        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index >= 0){
            $scope.tableOrderProperties = { sortName: orderProperties.name, sort: [[orderProperties.index, orderProperties.direction]] };
        }

        $scope.tableDefinition = {
            id: 'tableRegOutputEntries',
            origin: 'reg',
            objectType: 'RegisterEntry',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: angular.copy(columns),
            containerScope: $scope,
            useTableObjects: true
        }

        //endregion

        //region Mètodes

        $scope.newOutput = function(){
            $state.transitionTo('annexa.reg.output.entries.new');
        };

        $scope.createSuportingDocument = function(id) {
            $rootScope.loading(true);

            RegFactory.createSupportingDocument(id, 'OUTPUT', 'FILE')
                .then(function(data) {
                    if(window.navigator.msSaveOrOpenBlob) {
                        try {
                            window.navigator.msSaveOrOpenBlob(data, "pdf.pdf");
                        } catch(e) {
                            console.error(e.message);
                        }
                    } else {
                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        data.dispatchEvent(clickEvent);
                    }
                    $rootScope.loading(false);
                }).catch(function(error) {
                    $rootScope.loading(false);

                    DialogsFactory.error(ErrorFactory.getErrorMessage('register', 'evidencedocument', error && error.data ? error.data : ''));
            });
        }

        //endregion

    }])
    .controller('RegOutputNotificationsController',['$scope', '$rootScope', '$filter', '$state', 'RouteStateFactory', 'TableFilterFactory', 'GlobalDataFactory', 'Language', 'NotificationFactory', 'AnnexaFormlyFactory', 'DialogsFactory', 'AnnexaPermissionsFactory', function($scope, $rootScope, $filter, $state, RouteStateFactory, TableFilterFactory, GlobalDataFactory, Language, NotificationFactory, AnnexaFormlyFactory, DialogsFactory, AnnexaPermissionsFactory) {

        //region General
    	$scope.filterData = {
                state: '',
                loggedUser: $rootScope.LoggedUser.id
        };
        $scope.alerts = [];
        $scope.notificationStates = angular.copy(NotificationFactory.notificationGlobalStatus);
        $scope.notificationStates.unshift({
            id: '',
            icon: 'minus',
            style: 'text-black',
            name: $filter('translate')('global.literals.all'),
            orderStatus: 0
        });
        //endregion

        //region Filter

     // ORDENAR LES NOTIFICACIONS //
        $scope.tableOrderProperties = { sortName: 'createdDate', sort: [[3,'desc']] };

        
        $scope.filter = [
            { id: 'extract', type: 'text', order: 0, label: 'global.literals.extract', callAux: true},
            { id: 'notificationType', type: 'select', order: 1, label: 'global.literals.notificationTypes', dataType: 'LOCAL', data: GlobalDataFactory.notificationTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true},
            { id: 'globalStatus', type: 'select', order: 2, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: NotificationFactory.notificationGlobalStatus, nameProperty: 'name', addAll: true, callAux: true},
            { id: 'entryNumber', type: 'text', order: 3, label: 'global.reg.datatable.numberOutput', callAux: true},
            { id: 'dossierNumber', type: 'text', order: 4, label: 'global.literals.numExp', callAux: true},
            { id: 'thirdName', type: 'text', order: 5, label: 'global.literals.receivers', callAux: true},
            { id: 'userCreator', type: 'text', order: 6, label: 'global.literals.creator', callAux: true},
            { id: 'createdDate', type: 'dateRange', order: 7, label: 'global.literals.creation_date', callAux:true},
            { id: 'originEntryNumber', type: 'text', order: 8, label: 'global.literals.originEntryInputNumber', callAux:true }
        ];

        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
            $scope.filter.splice(3, 0, { id: 'registerEntryOffice', type: 'select', order: 0, label: 'global.literals.register_office', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryOffices, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true });                                      
        }

        var routeState = RouteStateFactory.getRouteState($state.current);

        if(routeState) {
            $scope.filter = routeState.state;
        }

        //endregion

        //region Table

        $scope.legend = [];

        var getFilterCall = function () {
            var filterCall = TableFilterFactory.getFilterCall($scope.filter);

            return filterCall;
        };

        var getFilterCallAux = function () {
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.filter);
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            if($scope.changeStatusFromHeader){
        		if($scope.filterData.state){
        			filterCallAux.globalStatus = {id: $scope.filterData.state};
        		}else{
        			filterCallAux.globalStatus = undefined;
        		}
        		
        		var filterAux = $linq($scope.filter).singleOrDefault(undefined, "x => x.id == 'globalStatus'");
        		if(filterAux){
        			var status = $linq($scope.notificationStates).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");
        			if(status){
                        filterAux.model = status;
        			}else{
        			    filterAux.model = {};
        			}
        		}
        		$scope.changeStatusFromHeader = false;
        	}else if(filterCallAux.globalStatus && filterCallAux.globalStatus.id != $scope.filterData.state){
        		$scope.filterData.state = filterCallAux.globalStatus.id;
        	}else if(!filterCallAux.state){
        		$scope.filterData.state = undefined;
        	}
            return filterCallAux;
        };

        var renderNotificationLastStatus = function (data, type, full, meta) {
            var orderedByDateStatuses = $linq(data).orderBy("x => x.createdDate").toArray();

            return (orderedByDateStatuses != undefined && orderedByDateStatuses.length > 0) ?
                orderedByDateStatuses[orderedByDateStatuses.length - 1].status[Language.getActiveColumn()] : '';
        };

        $scope.notificationGlobalStatus = NotificationFactory.notificationGlobalStatus;
        
        $scope.headerIconNotificationGlobalStatus = function(icon) {
            if($scope.notificationStates && $scope.notificationStates.length > 0 && $scope.filterData.state) {
                var status = $linq($scope.notificationStates).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");
                if(status) {
                    if(icon) {
                        return status.icon;
                    } else {
                        return status.style;
                    }
                }
            }
            if(icon) {
                return 'minus';
            } else {
                return 'false';
            }
        }
        
        var notificationGlobalStatusTitle = function() {
            var content = '';
 
            content += '<div>';
            content += '    <div class="btn-group dropdown">';
            content += '        <button type="button" class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown">';
            content += '            <span class="fa-stack">';
            content += '                <i class="fa fa-{{headerIconNotificationGlobalStatus(true)}} {{headerIconNotificationGlobalStatus(false)}} text-lg fa-stack-1x" aria-hidden="true"></i>';
            content += '            </span>';
            content += '            <span class="sr-only">{{\'global.literals.showOptions\'}}</span>';
            content += '        </button>';
            content += '        <div class="dropdown-menu">';
            content += '            <a class="dropdown-item" ng-repeat="state in notificationStates | orderBy:\'orderStatus\'" ng-click="setNotificationGlobalTypeDT(state);">';
            content += '                <span class="fa-stack">';
            content += '                    <i class="fa  fa-{{state.icon}} {{state.style}} text-lg fa-stack-1x"></i>';
            content += '                </span>';
            content += '                &nbsp;&nbsp;<span translate="global.literals.all" ng-if="state.id == \'\'"></span>';
            content += '                &nbsp;&nbsp;<span translate="{{state.name}}" ng-if="state.id != \'\'"></span>';
            content += '            </a>';
            content += '        </div>';
            content += '    </div>';
            content += '</div>';

            return content;
        }
        
        $scope.setNotificationGlobalTypeDT = function(state){
            $scope.filterData.state = ((!state.id || state.id == 0) ?  '' : state.id);
            if($scope.tableDefinition){ 
            	$scope.changeStatusFromHeader = true;
            	$scope.tableDefinition.reloadInternalData(true, true);
            }else{
                var scopeAux = angular.element('#tableNots').scope();
                if(scopeAux && scopeAux.tableDefinition && scopeAux.tableDefinition.reloadInternalData){
                    scopeAux.tableDefinition.reloadInternalData(true, true);
                }
            }
        }

        var notificationGlobalStatusRender = function(data, type, full, meta) {
            var content = '';

            if($scope.notificationStates && $scope.notificationStates.length > 0) {
                var state = $linq($scope.notificationStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                if(state) {
                    content += '<div class="text-center">';
                    content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
                    content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
                    content += '    </span>';
                    content += '</div>';
                }
            }

            return content;
        }
        
        var renderDossierNumberLink = function (data, type, full, meta) {
        	var content = '';
        	if(full.notification.dossier != null) {
        		if(full.notification.dossier.dossierStatus != 'FINISHED' && AnnexaPermissionsFactory.havePermission('process_dossier')) {
        			
        			var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                    var dossierProcessProfiles = [];
                    var dossierTransaction = null;
                    if(full.notification.dossier.dossierTransactions) {
                        _.forEach(full.notification.dossier.dossierTransactions, function (dt) {
                            if (dt.actual && dt.processProfiles) {
                                _.forEach(dt.processProfiles, function (item) {
                                    dossierProcessProfiles.push(item.profile.id);
                                })
                                var intersect = $linq(userProfiles).intersect(dossierProcessProfiles, "(x,y) => x == y").toArray();
                                if (intersect.length > 0) {
                                    dossierTransaction = dt;
                                }
                            }
                        });
                    }

                    if(dossierTransaction) {
                        content = '<a class="text-primary" href="" ng-click="openDossier('+data+','+dossierTransaction.id+',\'annexa.tram.pending.viewdossier\','+full.notification.dossier.bigDossier+')">' + full.notification.dossier.dossierNumber + '</a>';
                    }else{
                    	content = '<a class="text-primary" href="" ng-click="openDossier('+data+',undefined,\'annexa.tram.view.viewdossier\','+full.notification.dossier.bigDossier+')">' + full.notification.dossier.dossierNumber + '</a>';
                     }
            	} else {
            		content = '<a class="text-primary" href="" ng-click="openDossier('+data+',undefined,\'annexa.tram.view.viewdossier\','+full.notification.dossier.bigDossier+')">' + full.notification.dossier.dossierNumber + '</a>';
            	}
        	}
        	return content;
        }
        
        var columns = [
            { id: 'notification.globalStatus', width: '20px',  title: notificationGlobalStatusTitle(), render: notificationGlobalStatusRender, sortable: false },
            { id: 'notification.notificationType.'+ Language.getActiveColumn(), width: '150px', column: new DatabaseTranslatedColumn($filter, 'global.literals.notificationTypes', Language.getActiveColumn()) },
            { id: 'statuses', title: $filter('translate')('global.literals.state'), render: renderNotificationLastStatus, sortable: false},
            { id: 'createdDate', width: '120px', column: new DateTimeColumn($filter, 'global.literals.creation_date')},
            { id: 'notification.extract', width: '200px', title: $filter('translate')('global.literals.extract'), className: 'pre-line'},
            { id: 'notification.registerEntry.id', width: '120px', column: new UIRouterStateColumn($filter, 'global.reg.datatable.numberOutput','annexa.reg.output.entries.edit({output:\'[data]\'})', undefined, 'var number = full.notification.registerEntry.entryNumber; number;')},
            { id: 'notification.dossier.id', width: '120px', title: $filter('translate')('global.literals.dossierNumber'), render: renderDossierNumberLink},
            { id: 'third', column: new ThirdColumn($filter, 'global.literals.receivers')},
            { id: 'notification.createUser', column: new UserColumn($filter, 'global.literals.user') },
            { id: 'actions', columnName: 'id', width: '100px', className: 'text-center', column: new ActionsColumn($filter('translate')('global.literals.actions'),
                    new ActionButton('global.literals.see','showNotificationModal([data])','fa-eye', false), []), sortable: false}
        ]
        
        
        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
            columns.splice(5, 0, { id: 'notification.registerEntry.registerEntryOffice.acronym', width: '50px', title: $filter('translate')('global.literals.office') });
            if ($scope.tableOrderProperties.sort.index >= 5) {
            	$scope.tableOrderProperties.sort.index += 1;
            }
        }
        
        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        columns = getDatatableColumnsSettings(datatableSettings, 'datatable_register_entry_output_notifications', columns);

        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_register_entry_output_notifications', columns);

        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index >= 0){
            $scope.tableOrderProperties = {
                sortName: orderProperties.name,
                sort: [[orderProperties.index,orderProperties.direction]]
            };
        }

        $scope.tableDefinition = {
            id: 'tableNots',
            origin: 'reg',
            objectType: 'NotificationThird',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: angular.copy(columns),
            containerScope: $scope,
            useTableObjects: true
        };

        //endregion

        // region Mètodes

        $scope.showNotificationModal = function (notificationId) {
            var notification = $scope.tableObjects[notificationId].notification;

            var notificationViewModal = NotificationFactory.notificationViewModal(notification);

            AnnexaFormlyFactory.showAnnexaFormModal('modalViewNotification', notificationViewModal);
        };

        
        $scope.openDossier = function (id, idDT, url, bigDossier) {
        	if(bigDossier){
	        	DialogsFactory.confirm('global.literals.bigDossierMessage', 'global.literals.bigDossierMessageConfirm')
	            .then(function (data) {
	            	if(url == 'annexa.tram.view.viewdossier'){
	            		window.open($state.href(url, { dossier: id }));	            
	            	}else{
	            		window.open($state.href(url, { dossier: id, dossierTransaction:idDT }));
	            	}
	           }).catch(function (data) {
		               //Empty
		       });
        	}else{
        		if(url == 'annexa.tram.view.viewdossier'){
        			window.open($state.href(url, {dossier: id }), '_blank');
        		}else{
        			window.open($state.href(url, { dossier: id, dossierTransaction:idDT }));
        		}
        	}
        }

        
        $scope.$on('reloadNotificationTable', function(event, args) {
            $scope.tableDefinition.reloadInternalData(true, true);
        });

        // endregion

    }])
    .controller('RegOutputEntriesNewController',['$scope', '$rootScope', 'HeaderService', '$state', function($scope, $rootScope, HeaderService, $state) {

        //region General

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.reg.output.entries.new') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#new-registerentry','fa-floppy-o','global.literals.save',undefined,'saveRegistry'),
                    new HeadButton('btn grey-500 text-white breadDivButton m-r-sm', undefined, '#new-registerentry',undefined,'global.literals.cancel',undefined,'cancelRegistry')
                ];
            }
        });

        HeaderService.changeState($state.current);

        //endregion

    }])
    .controller('RegOutputEntriesEditController',['$scope', '$rootScope', 'HeaderService', '$state', 'RegFactory', function($scope, $rootScope, HeaderService, $state, RegFactory) {

        //region General

        $scope.registerEntry = angular.copy(RegFactory.regEntry);

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.reg.output.entries.edit' || message.state.name == 'annexa.reg.viewOutput') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton ml-sm-pt',undefined,'#edit-registerentry',undefined,'global.reg.literals.generateEvidenceDocument',undefined,'createSuportingDocument'),
                    new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-registerentry', 'fa fa-eye', 'global.documents.new.audit', undefined, 'auditRegisterEntry').setPermissions('admin_audit')                    
                ];
                                
                if ($rootScope.app.configuration.show_sicres_button && $rootScope.app.configuration.show_sicres_button.value) {                	
                	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-registerentry', undefined, 'global.reg.literals.generateDocumentSicres', undefined, 'generateDocumentSicres').setPermissions('generate_document_interoperable'));
                }
            }
        });

        HeaderService.changeState($state.current);

        //endregion
        
    }]);

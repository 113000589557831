/**
 * Created by osirvent on 06/09/2016.
 */
angular
    .module('annexaApp')
    .factory('NotificationFactory',['$q', '$http', '$filter', 'AnnexaModalFactory','globalModals','$rootScope', 'ErrorFactory', 'AnnexaFormlyFactory', 'Upload', 'DccumentsFactory', 'ThirdFactory', 'SecFactory','Language', 'CommonService', 'RestService', 'GlobalDataFactory', 'DialogsFactory', function($q, $http, $filter, AnnexaModalFactory, globalModals, $rootScope, ErrorFactory, AnnexaFormlyFactory, Upload, DccumentsFactory, ThirdFactory, SecFactory, Language, CommonService, RestService, GlobalDataFactory, DialogsFactory) {

        var factory = {};

        //region Globals

        factory.notificationGlobalStatus = [
            {id: 'PENDING', name: $filter('translate')('PENDING'), class: 'text-warn', icon: 'circle', style: 'text-warn'},
            {id: 'SEND', name: $filter('translate')('global.literals.SEND'), class: 'text-info', icon: 'circle', style: 'text-info'},
            {id: 'COMPLETE', name: $filter('translate')('global.literals.COMPLETE'), class: 'text-success', icon: 'circle', style: 'text-success'},
            {id: 'ERROR', name: $filter('translate')('global.literals.ERROR'), class: 'text-danger', icon: 'circle', style: 'text-danger'}
        ];
        //endregion

        //region General

        factory.getNotificationAddress = function (third, dossier) {
            var defaultAddress = $linq(third.addresses).firstOrDefault(undefined, "x => x.default");
            var allConsent = $linq(third.consents).firstOrDefault(undefined, "x => x.consentType == 'ALL'");

            if(dossier && third.consents) {
                var dossierConsent = $linq(third.consents).singleOrDefault(undefined, "x => x.consentType == 'DOSSIER' && ((x.dossier && x.dossier.id == " + dossier.id + ") || (x.dossierId == " + dossier.id + "))");
                var procedureConsent = $linq(third.consents).singleOrDefault(undefined, "x => x.consentType == 'PROCEDURE' && x.procedure && x.procedure.id == " + dossier.procedure.id);
                var familyProcedureConsent = dossier.procedure.family ? $linq(third.consents).singleOrDefault(undefined, "x => x.consentType == 'FAMILY_PROCEDURE' && x.familyProcedure && x.familyProcedure.id == " + dossier.procedure.family.id) : undefined;


                if (dossierConsent && dossierConsent.thirdAddress && !dossierConsent.thirdAddress.deleted) {
                    return dossierConsent.thirdAddress;
                } else if (procedureConsent && procedureConsent.thirdAddress && !procedureConsent.thirdAddress.deleted) {
                    return procedureConsent.thirdAddress;
                } else if (familyProcedureConsent && familyProcedureConsent.thirdAddress && !familyProcedureConsent.thirdAddress.deleted) {
                    return familyProcedureConsent.thirdAddress;
                }
            }

            return allConsent ? ((allConsent.thirdAddress.deleted)? defaultAddress : allConsent.thirdAddress) : defaultAddress;
        }

        factory.notificationsByThirds = function(thirdsAddress, notificationTypes, extract, thirdId, createNotificationFunction, pluginParams) {
            var deferred = $q.defer();
            var idPostal = $linq(notificationTypes).where("x => x.addressType == 'POSTAL'").selectMany("x => x.notificationTypes", "(n, a) => n").singleOrDefault(undefined, "x => x.selected");
            var idEmail = $linq(notificationTypes).where("x => x.addressType == 'TELEMATIC_EMAIL'").selectMany("x => x.notificationTypes", "(n, a) => n").singleOrDefault(undefined, "x => x.selected");
            var idPostalCode = $linq(notificationTypes).where("x => x.addressType == 'POSTAL_CODE'").selectMany("x => x.notificationTypes", "(n, a) => n").singleOrDefault(undefined, "x => x.selected");
            var idPhone = $linq(notificationTypes).where("x => x.addressType == 'TELEMATIC_MOBILEPHONE'").selectMany("x => x.notificationTypes", "(n, a) => n").singleOrDefault(undefined, "x => x.selected");

            var addressTypes = {};

            if (idPostal) {
                addressTypes.POSTAL = idPostal.id;
            }

            if (idEmail) {
                addressTypes.TELEMATIC_EMAIL = idEmail.id;
            }

            if (idPostalCode) {
                addressTypes.POSTAL_CODE = idPostalCode.id;
            }

            if (idPhone) {
                addressTypes.TELEMATIC_MOBILEPHONE = idPhone.id;
            }

            createNotificationFunction(thirdsAddress, addressTypes, extract, deferred, pluginParams);

            return deferred.promise;
        }

        //endregion

        //region New Modal

        factory.notificationNewModal = function(thirds, documents, dossier, registerEntry, addresses, docSelected, thirdsIdSelected) {
        	var documentsToAdd = [];        	
        	if (dossier && dossier.dossierTransactions) {
        		documentsToAdd = $linq($linq(dossier.dossierTransactions).selectMany("x => x.documents").toArray()).distinctBy("x => x.document.id").where("x => x.document.type.includeNotification == true").select("x => x.document").toArray();
        	}
        	if(documentsToAdd && documentsToAdd.length == 0 && documents && documents.length > 0){
        		if(documents[0].document){
        			documentsToAdd = $linq(documents).where("x => x.document.type.includeNotification == true").select("x => x.document").toArray();
        		}else{
        			documentsToAdd = $linq(documents).where("x => x.type.includeNotification == true").toArray();
        		}
        	}
        	
        	var documentsIdSelected = [];
        	if (documents && dossier) {
        		$linq(documents).foreach(
        				function (doc) { 
        					if(doc.type.includeNotification){
        						documentsIdSelected.push(doc.id); 
        					}
        				}
        			);
        	}
        	if(docSelected && docSelected.length > 0){
        		_.forEach(docSelected, function(docId){
        			if(!_.contains(documentsIdSelected, docId)){
        				documentsIdSelected.push(docId);
        			}
        		});
        	}
        	
        	var newModal = {
                title: 'global.literals.new_notification_title',
                size: 'modal-lg',
                fieldsDefinition: [],
                dossierTransactionId: undefined,
            	alerts: [],
                submitModal: function() {
                }
            };
            if(addresses){
            	newModal.fieldsDefinition.push({ type: 'field', id: 'thirds', fieldType: 'component', componentType: 'annexa-select-attendee',
                    data: {
                        title: 'global.literals.receivers',
                        thirds: thirds ? thirds : [],
                        addresses: addresses,
                        thirdsIdSelected: ((thirdsIdSelected && thirdsIdSelected.length > 0) ? thirdsIdSelected : undefined),
                        removeAddressesWithoutSelectedNotificationType: true
                    }
                });
        	}else{
        		newModal.fieldsDefinition.push({ type: 'field', id: 'thirds', fieldType: 'component', componentType: 'annexa-select-thirds',
                    data: {
                        title: 'global.literals.receivers',
                        thirds: thirds ? thirds : (dossier && dossier.thirds ? dossier.thirds : []),
                        dossier: dossier,
						removeAddressesWithoutSelectedNotificationType: true,
                        registerEntry: registerEntry
                    }
                });
        	}
            
            newModal.fieldsDefinition.push({ type: 'field', id: 'documents', fieldType: 'component', componentType: 'annexa-select-documents',
                data: {
                    title: 'global.literals.documents',
                    documents: documentsToAdd,
                    documentsIdSelected: documentsIdSelected.length > 0 ? documentsIdSelected : undefined		
                }
            });
            newModal.fieldsDefinition.push({ type: 'predefined', predefined: 'profile', id: 'profile', data: 'loggedUser', origin: 'RegisterEntryInput', colClass: ' ', required: true});
            newModal.fieldsDefinition.push({ type: 'predefined', predefined: 'section', id: 'section', data: 'loggedUser', colClass: ' ', required: true});
            
        	var defaultExpirationDays = $rootScope.app.configuration.notification_expiration_days.value;
        	if(dossier && dossier.procedure && dossier.procedure.expirationDaysNotification){
        		defaultExpirationDays = dossier.procedure.expirationDaysNotification;
        	}
        	newModal.fieldsDefinition.push({ type: 'field', id: 'expirationDaysNotification', fieldType: 'number', required: true, label: 'global.literals.expirationDays', defaultValue: defaultExpirationDays});
            
            
            newModal.fieldsDefinition.push({ type: 'field', id: 'extract', fieldType: 'textarea', required: true, label: 'global.literals.reason'});
            	            
            if (dossier || addresses) {
	            if(!registerEntry && $rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
	            	//Si el usuario puede registrar en mas de una oficina de registro
	                newModal.fieldsDefinition.unshift({ type: 'field', id: 'registerEntryOffice', fieldType: 'select', data: GlobalDataFactory.registerEntryOffices, required: true, label: 'global.literals.register_office' });
	            } else if (!registerEntry && $rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length == 1) {
	            	//Si el usuario solo puede registrar en una solo oficina de registro se le asignara esta directamente
	            	newModal.fieldsDefinition.unshift({ type: 'field', id: 'registerEntryOffice', fieldType: 'hidden', data: GlobalDataFactory.registerEntryOffices, defaultValue: GlobalDataFactory.registerEntryOffices[0], required: true});            	
	            }
            }

            return newModal;
        }

        factory.getNewNotifications = function(form, modal, parentIsModal) {
            form.form.$setSubmitted();

            var getDossier = function () {
                var fieldThirds = $linq(form.fields).singleOrDefault(undefined, "x => x.key == 'thirds'");

                if(fieldThirds) {
                    return fieldThirds.data.dossier ? { id: fieldThirds.data.dossier.id } : undefined;
                }

                return undefined;
            }
            
            var getProfile = function() {
                var profileSelected = $linq(form.fields).singleOrDefault(undefined, "x => x.key == 'profile'");

                if(profileSelected) {
                    return profileSelected.formControl.$viewValue.id ? { id: profileSelected.formControl.$viewValue.id } : undefined;
                }

                return undefined;
            }

            var getSection = function () {
                var sectionSelected = $linq(form.fields).singleOrDefault(void 0, "x => x.key == 'section'");

                if(sectionSelected) {
                    return sectionSelected.formControl.$viewValue.id ? { id: sectionSelected.formControl.$viewValue.id } : undefined;
                }

                return undefined;
            }
            
            var getRegisterEntryOffice = function () {
                var registerEntryOfficeSelected = $linq(form.fields).singleOrDefault(void 0, "x => x.key == 'registerEntryOffice'");

                if(registerEntryOfficeSelected) {
                    return registerEntryOfficeSelected.formControl.$viewValue.id ? { id: registerEntryOfficeSelected.formControl.$viewValue.id } : undefined;
                }

                return undefined;
            }

            var getRegisterEntry = function () {
                var fieldThirds = $linq(form.fields).singleOrDefault(undefined, "x => x.key == 'thirds'");
                if(fieldThirds) {
                   return fieldThirds.data.registerEntry ? { id: fieldThirds.data.registerEntry.id } : { profile: getProfile(), section: getSection(), registerEntryOffice: getRegisterEntryOffice()};
                }
                return { profile: getProfile(), section: getSection(), registerEntryOffice: getRegisterEntryOffice()};
            }

            var getAddresses = function() {
                var fieldThirds = $linq(form.fields).firstOrDefault(undefined, "x => x.key == 'thirds'");

                if(fieldThirds) {
                    var selectedThirds = $linq(fieldThirds.data.thirds).where("x => x.selected").toArray();

                    if(selectedThirds && selectedThirds.length > 0) {
                        var addressesSelected = [];

                        _.forEach(selectedThirds, function(item) {
                            var selectedAddress = $linq(item.addressesOrig).firstOrDefault(undefined, "x => x.selected");

                            if(selectedAddress) {
                                addressesSelected.push({ address: selectedAddress, third: ((item.third)?item.third:{id:item.id}) });
                            }
                        });

                        if(addressesSelected.length == selectedThirds.length) {
                            return addressesSelected;
                        }
                    }
                }

                return undefined;
            }

            var getDocuments = function() {
                var fieldDocuments = $linq(form.fields).singleOrDefault(undefined, "x => x.key == 'documents'");

                if(fieldDocuments) {
                    return $linq(fieldDocuments.data.documents).where("x => x.selected")
                        .select(function(x) {
                            return x;
                        }).toArray()
                }

                return undefined;
            }

            var getExtract = function () {
                var fieldExtract = $linq(form.fields).singleOrDefault(void 0, "x => x.key == 'extract'");

                if(fieldExtract && fieldExtract.value) {
                    return fieldExtract.value();
                }

                return undefined;
            }
            
            var getDossierTransactionId = function () {
            	if(modal.dossierTransactionId) {
            		return { id: modal.dossierTransactionId };
            	}
            	return undefined;
            }

            var extractCorrectLength = function(extract, addresses){
            	if(extract && addresses){
            		var minLengthArray = $linq(addresses).where("x=> x.address && x.address.selectedNotificationType && x.address.selectedNotificationType.limitSizeExtractNotifications && x.address.selectedNotificationType.limitSizeExtractNotifications > 0").select("x => x.address.selectedNotificationType.limitSizeExtractNotifications").toArray();
            		if(minLengthArray && minLengthArray.length > 0){
            			return $linq(minLengthArray).min();
            		}
            	}
            	return 0;
            }
            var addresses = getAddresses();
            var documents = getDocuments();
            var extract = getExtract();
            var extractMinlength = extractCorrectLength(((form && form.model && form.model.extract)?form.model.extract:undefined), addresses);
            if(!addresses) {
            	var fieldThirds = $linq(form.fields).firstOrDefault(undefined, "x => x.key == 'thirds'");
            	var selectedThirds = $linq(fieldThirds.data.thirds).where("x => x.selected").toArray();
            	
            	if(selectedThirds && selectedThirds.length > 0) {
            		modal.alerts.push({ msg: 'global.errors.channelNoValidSendNotifications' });
            	} else {
            		modal.alerts.push({ msg: 'global.errors.noDestinataris' });
            	}                
            } else if(!documents || documents.length == 0) {
                modal.alerts.push({ msg: 'global.errors.noDocuments' });
            } else if(form && form.model && form.model.extract && form && form.model && extractMinlength > 0 && form.model.extract.length > extractMinlength) {
                modal.alerts.push({ msg: ($filter('translate')('global.errors.maxLengthOfExtract')+' '+extractMinlength+' '+$filter('translate')('global.errors.actualLengthOfExtract')+ ' '+ form.model.extract.length+ '.') });
            } else {
                if(form.form.$valid) {
                    var newNotifications = [];

                    _.forEach(addresses, function (item) {
                        var status = undefined;
                        if(item && item.address && item.address.selectedNotificationType && item.address.selectedNotificationType.statuses && item.address.selectedNotificationType.statuses.length > 0){
                        	status = item.address.selectedNotificationType.statuses[0];
                        }
                        newNotifications.push({
                            notificationType: item.address.selectedNotificationType,
                            globalStatus: 'PENDING',
                            registerEntry: getRegisterEntry(),
                            dossier: getDossier(),
                            documents: documents,
                            thirds: [{ third: item.third, address: ((item.address && item.address.selectedNotificationType && item.address.selectedNotificationType.addressType == 'OTHER')?undefined:item.address), statuses: [{createdDate: new Date(), observations: extract, status: status}] }],
                            extract: form.model.extract,
                            createUser: { id: $rootScope.LoggedUser.id },
                            createdDate: new Date(),
                            dossierTransaction: getDossierTransactionId(),
                            expirationDays: form.model.expirationDaysNotification
                            //status: item.address.selectedNotificationType.statuses[0]
                        });
                    });
                    
                    var notificationsToValidate = $linq(newNotifications).where("x => x.notificationType.limitSizeDocumentsNotifications > 0").toArray();

                    var notiExceedNumberDocs = exceedsLimitNumberDocumentsNotification(newNotifications, documents.length);
                    var exceedsLimitInBytes = exceedsLimitSizeDocumentsNotification(notificationsToValidate); 
                    
                    if(notiExceedNumberDocs != null) {
                    	modal.alerts.push({ msg: $filter('translate')('global.errors.limitDocumentsNoti', { notificationType: notiExceedNumberDocs.notificationType.language1, maxLimitDocuments: notiExceedNumberDocs.notificationType.limitNumberDocumentsNotifications}) });
                    } else if(exceedsLimitInBytes > 0) {
                    	var limitInMB = parseFloat((exceedsLimitInBytes / 1000000).toFixed(2)); //Bytes a MegaBytes
                		DialogsFactory.confirm('DIALOGS_CONFIRMATION_MSG', $filter('translate')('DIALOGS_NOTIFICATION_MAX_DOCUMENT_SIZE_KO_1') + " (" + limitInMB + " MB) " + $filter('translate')('DIALOGS_NOTIFICATION_MAX_DOCUMENT_SIZE_KO_2')).then(function (data) {
                			$rootScope.$broadcast('created_multiple_notifications', { notifications: newNotifications, parentIsModal:((parentIsModal)?'isModal':'isNotModal') });
                        	modal.close();
                		}).catch(function(data) {
                            //Empty
                        });
                    } else {                    	
                    	$rootScope.$broadcast('created_multiple_notifications', { notifications: newNotifications, parentIsModal:((parentIsModal)?'isModal':'isNotModal') });
                    	modal.close();
                    }
                }
            }
        }
        
        var exceedsLimitSizeDocumentsNotification = function(notificationsToValidate) {
        	var res = parseInt(0);
        	
        	if (notificationsToValidate.length > 0) {
	        	for (var i=0; i < notificationsToValidate.length; i++) {
	        		var notification = notificationsToValidate[i];
	        		var totalDocumentsSize = parseInt(0);
	            	
	    			if (notification && 
	    					notification.notificationType && 
	    					notification.notificationType.limitSizeDocumentsNotifications) {
	    				
	    				$linq(notification.documents).foreach(
	        				function (doc) {
	        					if(doc.sizePDFDoc && doc.sizePDFDoc != ''){
	        						totalDocumentsSize = parseInt(totalDocumentsSize) + parseInt(doc.sizePDFDoc);
	        					}else if (doc.sizeDoc) {            				
	        						totalDocumentsSize = parseInt(totalDocumentsSize) + parseInt(doc.sizeDoc);
	                    		}
	        				}
	            		);
	    				
	    			}
	    			
	    			if (totalDocumentsSize > 0 && 
	    					notification && 
	            			totalDocumentsSize > notification.notificationType.limitSizeDocumentsNotifications) {
	    				//Al menos una notificacion supera el limite del tamanio de documentos
	    				res = notification.notificationType.limitSizeDocumentsNotifications; 
	    				break;
	    			}
	        	}
        	}
        	
			return res;
        }
        
        var exceedsLimitNumberDocumentsNotification = function(notificationsToValidate, totalDocuments) {
        	var noti;
        	var limitDocuments;
        	
        	if (notificationsToValidate.length > 0) {
	        	for (var i=0; i < notificationsToValidate.length; i++) {
	        		var notification = notificationsToValidate[i];
	            	
	    			if (notification &&
	    					notification.notificationType && 
	    					notification.notificationType.limitNumberDocumentsNotifications) {
	    				if(limitDocuments != null && limitDocuments > notification.notificationType.limitNumberDocumentsNotifications){
	    					limitDocuments = notification.notificationType.limitNumberDocumentsNotifications;
	    					noti = notification;
	    				} else if(limitDocuments == null) {
	    					limitDocuments = notification.notificationType.limitNumberDocumentsNotifications;
	    					noti = notification;
	    				}
	    			}
	        	}
        	}
        	
        	if(totalDocuments > limitDocuments) {
        		return noti;
        	}
        	
			return null;
        }

        //endregion

        //region View Modal

        factory.notificationViewModal = function(notification, origin) {
            var receivers = [];

            _.forEach(notification.thirds, function (item) {
                receivers.push({
                    id: item.third.id,
                    label: CommonService.getThirdCompleteName(item.third)
                })
            })

            var viewModal = {
                isEditable: true,
                inDivRow: true,
                hideSubmit: true,
                closeLabel: 'global.literals.close',
                title: 'global.literals.edit_notification_title',
                size: 'modal-lg',
                fieldsDefinition: [
                    { type: 'field', id: 'notificationType', fieldType: 'component', componentType: 'annexa-label-value', label: 'global.literals.type', className: 'col-lg-4',
                        data: {
                            value: notification.notificationType[Language.getActiveColumn()],
                            type: 'text',
                            rowClass: 'm-b-xs'
                        }
                    },
                    { type: 'field', id: 'extract', fieldType: 'component', componentType: 'annexa-label-value', label: 'global.literals.reason', className: 'col-lg-4',
                        data: {
                            value: notification.extract,
                            type: 'textarea',
                            rowClass: 'm-b-xs'
                        }
                    },
                    { type: 'field', id: 'expirationDays', fieldType: 'component', componentType: 'annexa-label-value', label: 'global.literals.expirationDays', className: 'col-lg-4',
                        data: {
                            value: notification.expirationDays,
                            type: 'text',
                            rowClass: 'm-b-xs'
                        }
                    },
                    { type: 'field', id: 'documents', fieldType: 'component', componentType: 'annexa-label-value', label: 'global.literals.documents', className: 'col-lg-12',
                        data: {
                            value: notification.documents,
                            type: 'documentList',
                            origin: 'NOTIFICATION'
                        }
                    },
                    { type: 'field', id: 'evidences', fieldType: 'component', componentType: 'annexa-notification-statuses-evidences', className: 'col-lg-12',
                        data: {
                            notification: notification,
                            canEdit: origin && origin == 'tram-view' ? false : true
                        }
                    }
                ],
                alerts: [],
                submitModal: function() {
                }
            }

            return viewModal;
        }

        factory.viewModal = function(notification) {
            var modal = angular.copy(globalModals.notificationModify);

            modal.data = notification;

            AnnexaModalFactory.showModal('modalEditNotification', modal);
        }

        //endregion

        //region NotificatonTypes Modal

        factory.newNotificationsByAddresses = function (addresses) {
            var newModal = {
                title: 'global.literals.createNewNotifications',
                size: '',
                icon: 'fa fa-check-square-o',
                fieldsDefinition: [
                    { type: 'field', id: 'addresses', fieldType: 'component', componentType: 'annexa-notification-addresses',
                        data: {
                            addresses: addresses,
                            notificationTypes: []
                        }
                    }
                ],
                alerts: [],
                submitModal: function() {
                	var notificationTypes = this.fields[0].data.notificationTypes;

                    $rootScope.$broadcast('selectedNotificationTypes', {
                        notificationTypes: notificationTypes,
                        modal: newModal,
                        thirdsAddresses: addresses
                    });
                }
            };

            AnnexaFormlyFactory.showAnnexaFormModal('modalNewNotification', newModal);
        }

        //endregion

        //region REST

        factory.createMultipleNotifications = function(notifications) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/notification/multiple',
                method: 'POST',
                data: JSOG.encode(notifications)
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.getEvidence = function(evidence) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/notification/evidence/' + CommonService.fixRepoIdForSendRest(evidence),
                method: 'GET',
            }).then(function(data) {
                deferred.resolve(data.data);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.createNotificationThirdStatus = function(notificationThirdStatus) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/notification_third_status',
                method: 'POST',
                data: JSOG.encode(notificationThirdStatus)
            }).then(function(data) {
                deferred.resolve(data.data);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        //endregion

        return factory;
    }]);